enum LinkType {
  internal = "internal",
  external = "external",
}

function airtable2Data(
  airtableEdges: { node: { data: AirtableRecord } }[]
): ArticleSummary[] {
  let airtableData: ArticleSummary[] = [];
  airtableEdges.map((edge: { node: { data: AirtableRecord } }) => {
    let recordLinkType, recordLink;
    let recordData = edge.node.data;
    if (recordData.Link) {
      recordLinkType = LinkType.external;
      recordLink = recordData.Link;
    } else {
      recordLinkType = LinkType.internal;
      if (recordData.Notes.raw !== "todo") {
        recordLink = `/${recordData.Category.toLowerCase()}/${recordData.Slug}`;
      } else {
        recordLink = "";
      }
    }
    airtableData.push({
      title: recordData.Name,
      tags: recordData.Tags,
      summary: recordData.Summary,
      linkType: recordLinkType,
      link: recordLink,
      date: recordData.Date,
    });
  });
  return airtableData;
}

export default airtable2Data;
