import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Tooltip from "../components/tooltip";
import SectionTitle from "../components/sectionTitle";
import ArticleBlock from "../components/articleBlock";
import Emoji, { EmojiNames } from "../components/emoji";
import Divider from "../components/divider";
import Footer from "../components/Footer";
import { SEO } from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import WebTitleSvg from "../images/web-title.inline.svg";
import classnames from "classnames";
import airtable2Data from "../utils/airtable2Data";
import { Disclosure, Transition } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

enum LinkType {
  internal = "internal",
  external = "external",
}

// Components

const CategoryContent = ({ content }: { content: CategoryContent }) => {
  const { category, list } = content;
  let emoji;
  switch (category) {
    case "Projects":
      emoji = "musical-score";
      break;
    case "Ideas":
      emoji = "moto-scooter";
      break;
    case "Experiences":
      emoji = "man-surfer";
      break;
  }

  return (
    <div className="basis-full flex items-start gap-5 flex-col sm:flex-row lg:flex-col">
      <h3 className="flex items-center font-nexa-bold text-[22px] gap-2 min-w-[180px] md:min-w-[200px] lg:w-fit">
        <Emoji emoji={emoji as EmojiNames} size={22} />
        {category}
      </h3>
      <div className="flex flex-col gap-6 w-full">
        {list.map((item) => (
          <ArticleBlock content={item} />
        ))}
        {category === "Experiences" ? (
          ""
        ) : (
          <Divider
            light
            className="max-w-[100px] mx-auto mt-4 xs:max-w-full lg:hidden"
          />
        )}
      </div>
    </div>
  );
};

// Main
const IndexPage = () => {
  const [titleMoved, setTitleMoved] = useState(false);

  function titleMove() {
    console.log(titleMoved);
    setTitleMoved(!titleMoved);
  }

  const data = useStaticQuery(graphql`
    query {
      projectsData: allAirtable(
        filter: { data: { Category: { eq: "Projects" } } }
        sort: { fields: rowIndex }
      ) {
        edges {
          node {
            data {
              Name
              Slug
              Category
              Date
              Link
              Summary
              Tags
              Notes {
                raw
              }
            }
          }
        }
      }
      ideasData: allAirtable(
        filter: { data: { Category: { eq: "Ideas" } } }
        sort: { fields: rowIndex }
      ) {
        edges {
          node {
            data {
              Name
              Slug
              Category
              Date
              Link
              Summary
              Tags
              Notes {
                raw
              }
            }
          }
        }
      }
      expData: allAirtable(
        filter: { data: { Category: { eq: "Experiences" } } }
        sort: { fields: rowIndex }
      ) {
        edges {
          node {
            data {
              Name
              Slug
              Category
              Date
              Link
              Summary
              Tags
              Notes {
                raw
              }
            }
          }
        }
      }
    }
  `);

  const articles: CategoryContent[] = [
    {
      category: "Projects",
      list: airtable2Data(data.projectsData.edges),
    },
    {
      category: "Ideas",
      list: airtable2Data(data.ideasData.edges),
    },
    {
      category: "Experiences",
      list: airtable2Data(data.expData.edges),
    },
  ];

  console.log(articles);
  return (
    <Layout pageTitle="Home Page">
      <div className="max-w-[1260px] px-6 xs:px-10 pt-20 mx-auto">
        {/* section start -- header */}
        <section className="flex font-nexa-bold mb-20 flex-col-reverse items-center gap-10 xs:flex-row xs:justify-between">
          <h1 className="hidden">奇思妙坦</h1>
          <div className="flex flex-col gap-4 items-center xs:items-start">
            <div
              onMouseEnter={titleMove}
              className={classnames(
                titleMoved ? "xs:translate-x-[209px]" : "",
                "w-fit transition duration-500 delay-500 ease-[cubic-bezier(1.000,-0.600,0.470,1.000)]"
              )}
            >
              <Tooltip message="Hi ~~">
                <Link to="/" className="w-fit">
                  <WebTitleSvg className="web-title" />
                </Link>
              </Tooltip>
            </div>

            <h2 className="text-3xl s400:text-4xl">Hello, I’m Tank Xu</h2>
            <p className="text-text-tertiary">
              <span>Product Designer</span>
              <span className="text-slash mx-1">/</span>
              <span>Creator</span>
            </p>
          </div>
          <div className="w-36 h-36 p-2 rounded-full bg-[linear-gradient(106deg,#ceffab,#8fd2ff,#f8f6ff,#fac8ff,#f3f0ff,#b9e2ff,#b4ffca)] bg-[length:400%_400%] animate-avatar-animation">
            <StaticImage
              src="../images/tank-avatar.jpg"
              alt="tank avatar"
              placeholder="blurred"
              className="rounded-full overflow-hidden [mask-image:radial-gradient(white,black)]"
            ></StaticImage>
          </div>
        </section>
        {/* section end -- header */}

        {/* section start -- about me */}
        <section className="mb-[60px]">
          <SectionTitle sectionName="About Me" />
          <div className="flex gap-10 flex-col s720:flex-row s720:gap-16 md:gap-20 s920:gap-16 lg:gap-[100px]">
            <div className="flex flex-col gap-3 leading-relaxed text-sm sm:max-w-[480px] lg:max-w-[580px]">
              <p>
                <span className="font-medium relative after:content-[''] after:absolute after:top-[10px] after:left-[-2px] after:-z-10 after:w-[calc(100%_+_4px)] after:h-2 after:bg-[#fdf3bf]">
                  创意工作者
                </span>
                ，从事多年 SaaS
                产品设计工作，在开发工具、协作工具、设计工具类产品上积累了丰富的行业经验，工作内容囊括用户体验、交互、视觉设计、设计系统开发、品牌策划。
              </p>
              <p>
                <span className="font-medium relative after:content-[''] after:absolute after:top-[10px] after:left-[-2px] after:-z-10 after:w-[calc(100%_+_4px)] after:h-2 after:bg-[#e8ffc3]">
                  新技术新工具的尝鲜者
                </span>
                ，擅长使用 JavaScript、Shell 等脚本语言，热衷于使用命令行工具和
                SaaS 产品整合各家 API
                实现应用连接、工作自动化，提升流程效率。热爱开发 Sketch、Figma
                脚本插件，macOS Quick Action 等实用工具。
              </p>
              <div className="flex flex-col-reverse">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button as="div">
                        <p className="w-fit text-text-blue cursor-pointer hover:text-text-link-active">
                          <span className={classnames(open ? "hidden" : "")}>
                            Read More
                          </span>
                          <span className={classnames(open ? "" : "hidden")}>
                            Collapse
                          </span>
                        </p>
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-500 ease-out"
                        enterFrom="transform h-0 pb-0 opacity-0"
                        enterTo="transform h-auto pb-3 opacity-100"
                      >
                        <Disclosure.Panel>
                          <div className="flex flex-col gap-3">
                            <p>
                              从高中时期开始观察国内外互联网行业的动态与发展，毕业后经历了国内头部
                              SaaS 公司 CODING
                              从初创到多轮融资，到被收购的全周期。
                            </p>
                            <p>
                              <span className="font-medium relative after:content-[''] after:absolute after:top-[10px] after:left-[-4%] after:-z-10 after:w-[108%] after:h-2 after:bg-[#ffe2fe]">
                                不断创造
                              </span>
                              ，有前端项目和 Arduino 工程开发经验，乐于使用
                              Docker、Serverless 等云原生技术。
                            </p>
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
            <div className="flex col-row flex-wrap gap-6 s400:gap-10 s720:gap-4 s720:flex-col s920:flex-row s920:gap-10 lg:gap-[60px]">
              <div>
                <p className="font-medium mb-[3px] whitespace-nowrap">
                  湖北美术学院
                </p>
                <div className="text-text-blue text-[13px] font-medium mb-2">
                  <Tooltip
                    message={
                      <span className="flex items-center gap-1">
                        <ArrowTopRightOnSquareIcon className="w-[14px]" />
                        湖北美院官网 - 新窗口打开
                      </span>
                    }
                  >
                    <a
                      className="hover:text-text-link-active whitespace-nowrap"
                      href="https://www.hifa.edu.cn/"
                      target="_blank"
                    >
                      视觉传达设计
                    </a>
                  </Tooltip>
                </div>
                <p className="font-a-mono text-xs text-text-tertiary flex space-x-[3px] items-center">
                  <span>2010</span>
                  <span className="w-[6px] h-[1px] bg-slash inline-block"></span>
                  <span>2014</span>
                </p>
              </div>
              <div>
                <p className="font-medium mb-[3px] flex space-x-1.5 items-center">
                  <span>腾讯云</span>
                  <span className="w-[1px] h-[18px] bg-slash inline-block"></span>
                  <span>CODING</span>
                </p>
                <div className="text-text-blue text-[13px] font-medium mb-2 flex space-x-2.5 ">
                  <Tooltip
                    message={
                      <span className="flex items-center gap-1">
                        <ArrowTopRightOnSquareIcon className="w-[14px]" />
                        CODING DevOps 官网 - 新窗口打开
                      </span>
                    }
                  >
                    <a
                      className="hover:text-text-link-active whitespace-nowrap"
                      href="https://coding.net"
                      target="_blank"
                    >
                      DevOps
                    </a>
                  </Tooltip>
                  <Tooltip
                    message={
                      <span className="flex items-center gap-1">
                        <ArrowTopRightOnSquareIcon className="w-[14px]" />
                        Cloud Studio 官网 - 新窗口打开
                      </span>
                    }
                  >
                    <a
                      className="hover:text-text-link-active whitespace-nowrap"
                      href="https://cloudstudio.net"
                      target="_blank"
                    >
                      Cloud Studio
                    </a>
                  </Tooltip>
                  <Tooltip
                    message={
                      <span className="flex items-center gap-1">
                        <ArrowTopRightOnSquareIcon className="w-[14px]" />
                        Nocalhost 官网 - 新窗口打开
                      </span>
                    }
                  >
                    <a
                      className="hover:text-text-link-active whitespace-nowrap"
                      href="https://nocalhost.dev"
                      target="_blank"
                    >
                      Nocalhost
                    </a>
                  </Tooltip>
                </div>
                <p className="font-a-mono text-xs text-text-tertiary flex space-x-[3px] items-center">
                  <span>2015</span>
                  <span className="w-[6px] h-[1px] bg-slash inline-block"></span>
                  <span>2022</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section end -- about me */}

        {/* section start -- my creativity */}
        <section>
          <SectionTitle sectionName="My Creativity" />
          <div className="flex gap-10 flex-col lg:flex-row">
            {articles.map((category) => (
              <CategoryContent content={category} />
            ))}
          </div>
        </section>
        {/* section end -- my creativity */}
        <Footer />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO>
    <script type="application/ld+json">{JSON.stringify({})}</script>
  </SEO>
);
