import * as React from "react";
import classnames from "classnames";

const SectionTitle = ({
  sectionName,
  className,
}: {
  sectionName: string;
  className?: string;
}) => {
  return (
    <div
      className={classnames(
        "font-nexa-bold text-[13px] text-text-tertiary mb-[14px]",
        className ? className : ""
      )}
    >
      {sectionName}
    </div>
  );
};

export default SectionTitle;
