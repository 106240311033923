import React, { MouseEvent } from "react";
import Tooltip from "./tooltip";
import Tag from "./tag";
import { Link } from "gatsby";
import classnames from "classnames";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

enum LinkType {
  internal = "internal",
  external = "external",
}

function expandTag(e: MouseEvent<HTMLDivElement>) {
  e.preventDefault();
  let tags = e.currentTarget.querySelectorAll(".tag-wrapper");
  tags.forEach((tagEle: any) => {
    let width = tagEle.firstChild.clientWidth;
    tagEle.style.width = `${width}px`;
  });
}

function collapseTag(e: MouseEvent<HTMLDivElement>) {
  e.preventDefault();
  let tags = e.currentTarget.querySelectorAll(".tag-wrapper");
  tags.forEach((tagEle: any) => {
    tagEle.style.width = null;
  });
}

const ArticleBlock = ({ content }: { content: ArticleSummary }) => (
  <div
    className="flex flex-col space-y-[6px]"
    onMouseEnter={expandTag}
    onMouseLeave={collapseTag}
  >
    <div className="font-sm font-medium flex justify-between items-baseline">
      <Tooltip
        message={
          content.linkType === LinkType.internal ? (
            content.link !== "" ? (
              "快来阅读我吧"
            ) : (
              "oh 被你发现了，这篇文章还没写完"
            )
          ) : (
            <span className="flex items-center gap-1">
              <ArrowTopRightOnSquareIcon className="w-[14px]" />
              访问项目主页，新标签打开
            </span>
          )
        }
        position="top"
      >
        {content.linkType === LinkType.internal && content.link !== "" ? (
          <Link
            to={content.link}
            className={"hover:text-text-blue transition-all"}
          >
            {content.title}
          </Link>
        ) : (
          <a
            href={content.link == "" ? undefined : content.link}
            className={classnames(
              content.link == ""
                ? "cursor-not-allowed text-text-secondary hover:text-text-tertiary"
                : "hover:text-text-blue",
              "transition-all"
            )}
            onClick={(e) => {
              content.link == "" ? e.preventDefault() : "";
            }}
            target="_blank"
          >
            {content.title}
          </a>
        )}
      </Tooltip>
      <p className="font-a-mono text-[11px] text-text-secondary ml-2 cursor-default">
        {content.date}
      </p>
    </div>
    <p className="text-text-tertiary text-[13px] leading-relaxed cursor-default">
      {content.summary}
    </p>
    <div className="flex items-center gap-[10px] pt-[2px]">
      {content.tags.map((tag) => (
        <div className="tag-wrapper transition-all duration-300 delay-150 tag w-3 overflow-x-hidden">
          <Tag name={tag} />
        </div>
      ))}
    </div>
  </div>
);

export default ArticleBlock;
